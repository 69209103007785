import { post } from './http'
import { query, mutation, QueryType } from './graphql'

const api = {
  // 获取用户（自己的）信息
  getUserInfo: () => query({
    operationName: 'getInfoByUser',
    data: `
      id
      name
      gender
      phoneNumber
      password
      token
      createdAt
      updatedAt
      avatar
    `
  }, QueryType.NoArgs),
  // 编辑用户信息
  editInfoByUser: (args = {}) => mutation({
    operationName: 'editInfoByUser',
    input: 'InputEditByUser!',
    data: `
      rowsAffected
    `,
    variables: {
      args: args
    }
  }),

  // 获取石材信息
  getStoneList: (args = {}) => query({
    operationName: 'getStoneList',
    input: 'InputGetStoneList',
    data: `
      data {
        id
        title
        image {
          url
          height
          width
        }
      }
      total
    `,
    variables: {
      args: args
    }
  }),
  // 获取单个石材信息
  getStone: (id) => query({
    operationName: 'getStone',
    id,
    data: `
      id
      title
      content
      price
      totalCount
      image {
        url
        height
        width
      }
      images {
        url
        height
        width
      }
      tags {
        tagID
        name
      }
    `
  }, QueryType.ID),
  // 搜索石材信息
  searchImageList: (args = {}) => query({
    operationName: 'searchImageList',
    input: 'InputSearchImageList',
    data: `
      data {
        id
        url
        height
        width
        stone {
          id
          title
        }
      }
      total
    `,
    variables: {
      args: args
    }
  }),

  // 获取收藏夹内石材信息
  getCollectionListByUser: (args = {}) => query({
    operationName: 'getCollectionListByUser',
    input: 'InputGetCollectionByUser!',
    data: `
      data {
        id
        title
        image {
          url
          height
          width
        }
      }
      total
    `,
    variables: {
      args: args
    }
  }),

  // 获取默认收货地址
  getDefaultAddress: () => query({
    operationName: 'getDefaultAddressByUser',
    data: `
      id
      username
      phoneNumber
      region
      address
      isDefault
    `
  }, QueryType.NoArgs),
  // 获取收货地址列表
  getAddressListByUser: (args = {}) => query({
    operationName: 'getAddressListByUser',
    input: 'InputGetAddressListByUser',
    data: `
      data {
        id
        username
        phoneNumber
        region
        address
        isDefault
      }
      total
    `,
    variables: {
      args: args
    }
  }),
  // 添加收货地址
  addAddressByUser: (args = {}) => mutation({
    operationName: 'addAddressByUser',
    input: 'InputAddAddressByUser!',
    data: `
      id
    `,
    variables: {
      args: args
    }
  }),
  // 编辑收货地址
  editAddressByUser: (args = {}) => mutation({
    operationName: 'editAddressByUser',
    input: 'InputEditAddressByUser!',
    data: `
      rowsAffected
    `,
    variables: {
      args: args
    }
  }),
  // 删除收货地址
  delAddressByUser: (id) => mutation({
    operationName: 'delAddressByUser',
    id,
    data: `
      rowsAffected
    `
  }, true),

  // 获取收藏夹信息
  getFolderListByUser: (args = {}) => query({
    operationName: 'getFolderListByUser',
    input: 'InputGetFolderListByUser',
    data: `
      data {
        id
        name
        description
        images
      }
      total
    `,
    variables: {
      args: args
    }
  }),
  // 添加收藏夹
  addFolderByUser: (args = {}) => mutation({
    operationName: 'addFolderByUser',
    input: 'InputAddFolderByUser!',
    data: `
      id
    `,
    variables: {
      args: args
    }
  }),
  // 编辑收藏夹
  editFolderByUser: (args = {}) => mutation({
    operationName: 'editFolderByUser',
    input: 'InputEditFolderByUser!',
    data: `
      rowsAffected
    `,
    variables: {
      args: args
    }
  }),
  // 删除收藏夹
  delFolderByUser: (id) => mutation({
    operationName: 'delFolderByUser',
    id,
    data: `
      rowsAffected
    `
  }, true),
  // 添加收藏
  addCollectionByUser: (args = {}) => mutation({
    operationName: 'addCollectionByUser',
    input: 'InputAddCollectionByUser!',
    data: `
      id
    `,
    variables: {
      args: args
    }
  }),

  // 获取支付二维码
  getPaymentNative: (args = {}) => query({
    operationName: 'getPaymentNative',
    input: 'InputGetPayment!',
    data: `
      codeUrl
    `,
    variables: {
      args: args
    }
  }),

  // 获取图包
  getImagePack: (args = {}) => query({
    operationName: 'getImagePack',
    input: 'InputGetImagePack!',
    data: `
      isSuccess
    `,
    variables: {
      args: args
    }
  }),

  // 获取订单信息
  getOrderListByUser: (args = {}) => query({
    operationName: 'getOrderListByUser',
    input: 'InputGetOrderListByUser',
    data: `
      data {
        id
        tradeStatus
        orderNo
        totalPrice
        orderDetail {
          mainImage
          title
          price
          count
        }
      }
      total
    `,
    variables: {
      args: args
    }
  }),
  // 获取单个订单信息
  getOrderByUser: (id) => query({
    operationName: 'getOrderByUser',
    id,
    data: `
      id
      tradeStatus
      orderNo
      paidAt
      totalPrice
      usedToken
      paidPrice
      username
      phoneNumber
      region
      address
      createdAt
      orderDetail {
          mainImage
          title
          price
          count
          stoneID
      }
    `
  }, QueryType.ID),
  // 添加订单
  addOrderByUser: (args = {}) => mutation({
    operationName: 'addOrderByUser',
    input: 'InputAddOrderByUser!',
    data: `
      id
    `,
    variables: {
      args: args
    }
  }),
  // 取消订单
  cancelOrderByUser: (id) => mutation({
    operationName: 'cancelOrderByUser',
    id,
    data: `
      rowsAffected
    `
  }, QueryType.ID),

  // 获取图片信息
  getImageList: (args = {}) => query({
    operationName: 'getImageList',
    input: 'InputGetImageList',
    data: `
      data {
        id
        url
        height
        width
        stone {
          id
          title
        }
        tags {
          id
          name
        }
      }
      total
    `,
    variables: {
      args: args
    }
  }),

  // 获取石材筛选
  getAllFilterTagList: () => query({
    operationName: 'getAllFilterTagList',
    data: `
      id
      name
      isColor
      isMaterial
      isTexture
    `
  }, QueryType.NoArgs),

  addLog: (args = {}) => mutation({
    operationName: 'addLog',
    input: 'InputAddLog!',
    data: `
      id
    `,
    variables: {
      args: args
    }
  }),

  // 获取热门标签
  getHotTagList: () => query({
    operationName: 'getHotTagList',
    data: `
      id
      name
    `
  }, QueryType.NoArgs),

  // 登录
  login: (params = {}) => post('/user/token', params),
  loginByWXCode: (params = {}) => post('/user/wx/token', params)
}

// Vue.use
const install = (Vue) => {
  if (install.installed) return
  install.installed = true

  // 定义属性到Vue原型中，这样每一个组件就可以通过this.$api.xxx(data) 去发送请求
  // Vue.prototype.$api = api
  Object.defineProperties(Vue.prototype, {
    $api: {
      get () {
        return api
      }
    },
    api: {
      get () {
        return api
      }
    }
  })
}

const getOSSToken = api.getOSSToken

export {
  install as default,
  getOSSToken
}

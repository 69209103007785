<template>
  <div class="text-not-selected tag-list">
    <a-tag class="tag" v-for="(item, index) of tagList" :key="index" @click="handleSelect(item.name)">{{ item.name }}</a-tag>
  </div>
</template>

<script>
export default {
  name: 'SearchTip',

  data () {
    return {
      tagList: []
    }
  },

  beforeMount () {
    this.getHotTagList()
  },

  methods: {
    // 选择
    handleSelect (keyword) {
      this.$emit('ok', keyword)
    },

    // 获取热门标签数据
    getHotTagList () {
      this.api.getHotTagList().then(res => {
        this.tagList = res.data.getHotTagList
      }).catch(() => {
        this.isLoading = false
      })
    }
  }
}
</script>

<style scoped>
.tag-list {
  margin-top: 30px;
  padding: 0 80px;
}
.tag {
  font-size: 20px;
  padding: 15px 20px;
  margin-bottom: 20px;
  cursor: pointer;
}
</style>

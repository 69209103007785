<template>
  <div id="app">
    <!-- 页面内容 -->
    <a-config-provider :locale="locale">
      <keep-alive>
        <router-view v-if='$route.meta.keepAlive'></router-view>
      </keep-alive>
    </a-config-provider>
    <a-config-provider :locale="locale">
      <router-view v-if='!$route.meta.keepAlive'></router-view>
    </a-config-provider>
  </div>
</template>

<script>
import zhCN from 'ant-design-vue/es/locale/zh_CN'

export default {
  name: 'App',

  data () {
    return {
      locale: zhCN
    }
  },

  beforeMount () {
    if (!this.$store.getters.isDev) {
      this.$nextTick(() => {
        // 禁止右键
        // eslint-disable-next-line no-new-func
        document.oncontextmenu = new Function('event.returnValue=false')
        // 禁止拖拽图片
        // eslint-disable-next-line no-new-func
        document.ondragstart = new Function('return false')
      })
      // 禁止调试
      window.addEventListener('keydown', this.preventKeydown)
    }
  },

  beforeDestroy () {
    if (!this.$store.getters.isDev) {
      window.removeEventListener('keydown', this.preventKeydown)
    }
  },

  methods: {
    preventKeydown (e) {
      if (e && e.keyCode === 83 && (navigator.platform.match('Mac') ? e.metaKey : e.ctrlKey)) {
        e.preventDefault()
      }
      if (e && e.keyCode === 67 && (navigator.platform.match('Mac') ? (e.metaKey && e.shiftKey) : true)) {
        e.preventDefault()
      }
      if (e && e.keyCode === 123) {
        e.preventDefault()
      }
      if (e && e.ctrlKey && e.shiftKey && e.keyCode === 73) {
        e.preventDefault()
      }
    }
  }
}

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>

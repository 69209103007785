<template>
  <a-layout style="min-height: 100vh">
    <!-- 主体 -->
    <a-layout class="container">
      <!-- 头部 -->
      <a-layout-header class="top-bar text-not-selected" style="background: #fff; padding: 0; height: 72px; line-height: 72px">
        <div class="top-bar-row">
          <a-col>
            <!-- logo -->
            <div class="brand" @click="handleGoHome">
              <div class="logo">
                <img alt="logo" src="@/assets/images/logo.png" />
                <h1>石上良品</h1>
              </div>
            </div>
          </a-col>

          <a-col>
            <a-form-model class="search-input" ref="form" :model="form">
              <a-form-model-item prop="keyword">
                <a-input ref="input" placeholder="搜索" v-model="form.keyword" allowClear
                         @keyup.enter.native="handleSearch" @change="handleSearch"
                         @focus="isShowSearchTip = true" @blur="handleBlur" />
              </a-form-model-item>
            </a-form-model>
          </a-col>

          <a-col class="right-block">
            <div class="image-page-btn" @click="handleGoImagePage">漫游</div>
<!--            <div class="image-page-btn" @click="visible = true">筛选</div>-->
            <stone-filter :visible="visible" @cancel="visible = false" />
            <img alt="avatar" class="user-avatar" v-if="$store.state.userInfo.avatar"
                 :src="$store.state.userInfo.avatar" @click="handleGoPersonalCenter" />
            <a-icon v-else class="user-avatar-icon" type="smile" @click="handleGoPersonalCenter" />
            <a-menu mode="horizontal" :style="{ height: '72px', lineHeight: '72px', borderBottom: 'none' }">
              <a-sub-menu>
                <span slot="title"><a-icon style="margin-right: 0" type="down" /></span>
<!--                <a-menu-item key="setting">-->
<!--                  设置-->
<!--                </a-menu-item>-->
                <a-menu-item key="logout" @click="handleLogout">
                  退出登录
                </a-menu-item>
              </a-sub-menu>
            </a-menu>
          </a-col>
        </div>
      </a-layout-header>

      <!-- 展示搜索提醒 -->
      <a-layout-content v-if="isShowSearchTip" style="margin: 72px auto 0 auto">
        <search-tip @ok="handleSelectKeyword"/>
      </a-layout-content>

      <a-layout-content v-else style="margin: 72px auto 0 auto">
        <!-- 主体 -->
        <router-view v-if="isRouterAlive"></router-view>
      </a-layout-content>

      <a-layout-footer class="footer text-not-selected">
        石上良品 ©2022
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>
<script>
import StoneFilter from '@/pages/stone/StoneFilter'
import SearchTip from '@/pages/stone/SearchTip'
export default {
  // TODO 后退页面菜单栏不联动更新选中
  // https://segmentfault.com/q/1010000038219480
  name: 'Home',
  components: { SearchTip, StoneFilter },
  data () {
    return {
      isRouterAlive: true,
      menuList: this.$store.state.menuList,
      form: {
        keyword: this.keyword
      },

      visible: true,

      isShowSearchTip: false
    }
  },

  computed: {
    haveQuery () {
      return this.keyword !== undefined && this.keyword !== ''
    }
  },

  props: {
    keyword: {
      type: String,
      default: ''
    }
  },

  methods: {
    // View刷新重载
    routerViewReload () {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    },

    // 退出登录
    handleLogout () {
      this.$store.commit('clearAll')
      this.$router.replace('/login')
    },

    // 搜索
    handleSearch (e) {
      if (e.type !== 'keyup' && e.type !== 'click') return
      // if (this.$route.path === '/' && this.keyword === this.form.keyword) return
      this.$router.push({
        path: '/image-list',
        query: {
          q: this.form.keyword
        }
      })
      this.$refs.input.blur()
    },

    // 失焦
    handleBlur (e = {}) {
      if (e.type === 'blur') {
        // 不得已的解决办法
        setTimeout(() => {
          this.isShowSearchTip = false
          this.handleSearch({ type: 'click' })
        }, 150)
        return
      }
      this.isShowSearchTip = false
      this.handleSearch({ type: 'click' })
    },
    // 点击关键词搜索
    handleSelectKeyword (keyword) {
      this.form.keyword = keyword
      this.handleBlur()
    },

    // 跳转首页
    handleGoHome () {
      if (this.$store.state.currentPath === '/' && !this.haveQuery) return
      this.form.keyword = ''
      this.$router.replace('/')
    },

    // 跳转个人中心
    handleGoPersonalCenter () {
      if (this.$store.state.currentPath === '/user') return
      this.$router.push('/user')
    },

    // 跳转石材页
    handleGoImagePage () {
      if (this.$store.state.currentPath === '/image-list') return
      this.$router.replace('/image-list')
    }
  }
}
</script>

<style>
.container {
  position: relative;
}

.top-bar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 888;
  box-shadow: 0 0 5px 1px rgba(0,0,0,0.2);
}
.top-bar-row {
  display: grid;
  grid-template-columns: 150px auto 450px;
  align-items: center;
  align-self: center;
}

.brand {
  z-index: 1;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  cursor: pointer;
}
.brand .logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.brand .logo img {
  width: 36px;
  margin-right: 12px;
  box-shadow: 0 0 5px 1px rgba(0,0,0,0.2);
  border-radius: 50%;
}
.brand .logo h1 {
  vertical-align: text-bottom;
  font-size: 16px;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 700;
  color: var(--blue-color);
  white-space: nowrap;
  margin-bottom: 0;
}
.brand .logo h1 :local {
  animation: fadeRightIn 300ms ;
  animation-fill-mode: both;
}

.search-input input {
  line-height: 50px;
  height: 50px;
  border-radius: 30px;
  padding: 0 20px;
}
.search-input .ant-form-item {
  margin-bottom: 0;
}

.right-block {
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-page-btn {
  margin-right: 10px;
  color: var(--blue-color);
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
  box-sizing: border-box;
  height: 72px;
}
.image-page-btn:hover {
  border-bottom: 3px solid var(--blue-color);
}
.user-avatar {
  width: 40px;
  height: 40px;
  cursor: pointer;
  margin-right: 10px;
  border-radius: 50%;
  border: 2px solid rgba(0, 0, 0, 0);
}
.user-avatar:hover {
  border: 2px solid var(--blue-color);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}
.user-avatar-icon {
  font-size: 30px;
  margin-right: 10px;
  cursor: pointer;
}

.footer {
  text-align: center;
}
</style>

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import Home from '../pages/Home.vue'
import moment from 'moment'

const original = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return original.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: Home,
    children: [
      { path: '/', name: 'StoneList', component: () => import('@/pages/stone/StoneList'), meta: { title: '首页' }, props: route => ({ keyword: route.query.q, color: route.query.color, material: route.query.material, texture: route.query.texture }) },
      // { path: '/stone-list', name: 'StoneList', component: () => import('@/pages/stone/StoneList'), meta: { title: '品种页', keepAlive: true }, props: route => ({ keyword: route.query.q }) },
      { path: '/image-list', name: 'ImageList', component: () => import('@/pages/stone/ImageList'), meta: { title: '漫游' }, props: route => ({ keyword: route.query.q }) },
      { path: '/detail/:stoneID', name: 'StoneDetail', component: () => import('@/pages/stone/StoneDetail'), meta: { title: '详情页' }, props: true },
      { path: '/user', name: 'PersonalCenter', component: () => import('@/pages/user/PersonalCenter'), meta: { title: '个人中心' } },
      { path: '/collection/:folderID', name: 'Collection', component: () => import('@/pages/collection/Collection'), meta: { title: '收藏夹' }, props: true },
      { path: '/add-order', name: 'AddOrder', component: () => import('@/pages/order/AddOrder'), meta: { title: '获取样品' } },
      { path: '/address-list', name: 'AddressList', component: () => import('@/pages/address/AddressList'), meta: { title: '收货地址列表' } },
      { path: '/order-list', name: 'OrderList', component: () => import('@/pages/order/OrderList'), meta: { title: '订单列表' } },
      { path: '/order/:orderID', name: 'OrderDetail', component: () => import('@/pages/order/OrderDetail'), meta: { title: '订单详情' }, props: true }
    ],
    props: route => ({ keyword: route.query.q })
  },
  { path: '/login', name: 'Login', component: () => import('@/pages/login/Login'), meta: { title: '登录' } },
  { path: '/wx-login', name: 'WXLogin', component: () => import('@/pages/login/WX'), meta: { title: '登录' } }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // 路由发生变化修改页面title
  document.title = to.meta.title ? to.meta.title + ' | 石上良品' : '石上良品'
  if (to.name === 'Login' || to.name === 'WXLogin') return next()
  // 保存当前页面
  store.commit('setCurrentPath', to.path)
  // 没有token就跳转登录
  if (!store.state.token) return router.push('/login').then()
  // token过期也跳转
  if (store.state.tokenExpire < moment().format('X')) return router.push('/login').then()
  next()
})

export default router

<template>
  <div>
    <a-form-model layout="inline" ref="form" :model="form">
      <a-form-model-item prop="color">
        <a-select v-model="form.color" placeholder="颜色" style="width: 80px" allowClear
                  showSearch optionFilterProp="children" @change="handleSearch">
          <a-select-option v-for="(item, key) of colorTagList" :value="item.id" :key="key">
            {{item.name}}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item prop="material">
        <a-select v-model="form.material" placeholder="材质" style="width: 80px" allowClear
                  showSearch optionFilterProp="children" @change="handleSearch">
          <a-select-option v-for="(item, key) of materialTagList" :value="item.id" :key="key">
            {{item.name}}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item prop="texture">
        <a-select v-model="form.texture" placeholder="纹理" style="width: 80px" allowClear
                  showSearch optionFilterProp="children" @change="handleSearch">
          <a-select-option v-for="(item, key) of textureTagList" :value="item.id" :key="key">
            {{item.name}}
          </a-select-option>
        </a-select>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>

<script>
export default {
  name: 'StoneFilter',

  props: {
    visible: { type: Boolean, default: false }
  },

  data () {
    return {
      form: {},
      colorTagList: [],
      materialTagList: [],
      textureTagList: []
    }
  },

  beforeMount () {
    this.getAllFilterTagList()
  },

  methods: {
    // 获取数据
    getAllFilterTagList () {
      this.api.getAllFilterTagList(this.args).then(res => {
        const list = res.data.getAllFilterTagList
        const colorTagList = []
        const materialTagList = []
        const textureTagList = []
        for (const v of list) {
          if (v.isColor) {
            if (v.id + '' === this.$route.query.color) this.form.color = v.id
            colorTagList.push(v)
          }
          if (v.isMaterial) {
            if (v.id + '' === this.$route.query.material) this.form.material = v.id
            materialTagList.push(v)
          }
          if (v.isTexture) {
            if (v.id + '' === this.$route.query.texture) this.form.texture = v.id
            textureTagList.push(v)
          }
        }
        this.colorTagList = colorTagList
        this.materialTagList = materialTagList
        this.textureTagList = textureTagList
      })
    },

    // 搜索
    handleSearch (v) {
      this.$router.push({
        path: '/',
        query: {
          color: this.form.color,
          material: this.form.material,
          texture: this.form.texture
        }
      })
      this.$router.go(0)
    },

    // 重置搜索
    handleResetSearchForm () {
      this.form = {}
      this.$refs.form.resetFields()
    },

    // 取消
    handleCancel () {
      this.$emit('cancel')
    }
  }
}
</script>

<style scoped>

</style>

import Vue from 'vue'
import Vuex from 'vuex'
import jwtDecode from 'jwt-decode'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    baseURL: '',
    apiBaseURL: '',
    apiGraphQLBaseURL: '',
    currentPath: '/',
    token: '',
    id: -1,
    role: '',
    tokenExpire: 0,
    loginCallback: [],
    ossToken: undefined,
    userInfo: {},
    stoneID: null,
    addressData: null,
    isDev: process.env.NODE_ENV === 'development'
  },

  getters: {
    isDev (state) {
      return state.isDev
    }
  },

  mutations: {
    // 设置回调函数
    addLoginCallBack (state, func) {
      state.loginCallback.push(func)
    },

    // 设置当前页面path
    setCurrentPath (state, currentPath) {
      state.currentPath = currentPath
      localStorage.setItem('currentPath', state.currentPath)
    },

    // 设置token
    setToken (state, token) {
      state.token = token
      localStorage.setItem('token', state.token)
      // 加载jwt内的信息
      if (state.token) {
        const decode = jwtDecode(state.token)
        state.id = decode.id
        state.role = decode.role
        state.tokenExpire = decode.exp
      }
    },

    // 设置下单的石材id
    setStoneID (state, id) {
      state.stoneID = id
    },

    // 设置收货地址
    setAddress (state, addressData) {
      state.addressData = addressData
    },

    // 清除所有缓存
    clearAll (state) {
      state.token = ''
      state.id = -1
      state.role = ''
      state.tokenExpire = 0
      state.userInfo = {}
      state.routerList = []
      localStorage.removeItem('token')
      localStorage.removeItem('userInfo')
    },

    // 加载所有缓存数据
    loadAll (state) {
      // 加载当前页面
      state.currentPath = localStorage.getItem('currentPath') || '/'

      // 加载url
      state.apiBaseURL = process.env.VUE_APP_API_BASE_URL
      state.apiGraphQLBaseURL = process.env.VUE_APP_API_GRAPHQL_BASE_URL
      state.baseURL = process.env.VUE_APP_BASE_URL

      // 加载token
      // if (state.isDev) {
      //   localStorage.setItem('token', process.env.VUE_APP_TOKEN_MANAGER)
      // }
      state.token = localStorage.getItem('token') || ''
      // 加载jwt内的信息
      if (state.token) {
        const decode = jwtDecode(state.token)
        state.id = decode.id
        state.role = decode.role
        state.tokenExpire = decode.exp
      }

      // 加载用户信息
      const userInfo = localStorage.getItem('userInfo') || '{}'
      state.userInfo = JSON.parse(userInfo)
    },

    // 设置阿里云 OSS Token
    setOSSToken (state, ossToken) {
      state.ossToken = ossToken
    },

    // 获取阿里云 OSS Token
    clearOSSToken (state) {
      state.ossToken = undefined
    },

    // 设置用户信息
    setUserInfo (state, userInfo) {
      state.userInfo = userInfo
      localStorage.setItem('userInfo', JSON.stringify(state.userInfo))
    }
  },

  actions: {},

  modules: {}
})
